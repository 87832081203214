.trendBar {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/trend/trendBgImg.png');
  display: flex;
  background-size: cover;
  margin-right: 30px;
  /* align-items: center; */
}

.trendLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  margin-top: 5px;
  font-size: 5px;
  width: 150px;
  min-width: 150px;
}

.itemCoinWrappedDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.exampleImg {
  width: 50px;
}

.trendCoins {
  align-items: center;
}

.itemCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-width: 200px;
}

.trendBarSpan {
  color: black;
  font-size: 20px;
  font-weight: 1000;
}

.coinNameSpan {
  color: black;
  size: 10px;
}

@media only screen and (max-width: 824px) {
  .trendBar {
    overflow-x: scroll;
    justify-content: start;
  }
}
