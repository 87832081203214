.detailsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.detailsHeaderDiv {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.headerLeftDiv {
  width: 800px;
  /* height: 200px; */
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
}

.firstHeaderDiv {
  display: flex;
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
}

.secondHeaderDiv {
  margin-top: 30px;
  display: flex;
  height: 60px;
  width: 100%;
  background-image: linear-gradient(to right, #dc1bd9, #4eb4c3);
  border-radius: 20px;
  padding: 2px 2px 2px 2px;
}
.secondHeaderWrappedDiv {
  background-color: #232c29;
  width: 100%;
  color: white;
  font-size: 17px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  align-items: center;
  border-radius: 20px;
  display: flex;
}
.secondHeaderWrappedDiv img {
  width: 20px;
}
.secondHeaderWrappedDiv:hover {
  cursor: pointer;
}
.logo1Div {
  display: flex;
  width: 100%;
}

.logoLeftDiv {
  height: 60px;
  width: 60px;
  /* display: flex;
    justify-content: center; */
  /* flex: 1; */
}
.logoLeftDiv img {
  width: 50px;
  height: 50px;
  /* text-align: center; */
}

.logoMiddleDiv {
  /* height: 60px; */
  width: 300px;
  margin-right: 100px;

  /* flex:4; */
}

.nameDiv {
  /* height: 30px; */
  color: white;
  align-items: center;
  margin-bottom: 5px;
}
.nameDiv span {
  font-size: 25px;
  font-weight: bold;
}
.symbolTierDiv {
  height: 30px;
  /* color: white; */
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.logoRightDiv {
  /* flex: 5; */
}
.logoVoteDivWrapped {
  width: 300px;
  height: 50px;
  background-image: linear-gradient(to right, #ce3ca7, #4291ae);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
}
.logoVoteBtn {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #f19642, #fdbc61);
  border-radius: 20px;
  color: black;
  font-weight: bold;
  font-size: 20px;
  border: none;
}
.logoVoteBtn:hover {
  background-image: linear-gradient(to right, #ce3ca7, #4291ae);
  cursor: pointer;
}
.symbolTierDiv span {
  font-size: 20px;
  color: white;
}

.thirdHeaderDiv {
  margin-top: 20px;
  display: flex;
}
.thirdHeaderDiv img {
  cursor: pointer;
}

.fourthHeaderDiv {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  display: flex;
  text-align: center;
}
.fourthHeaderDiv .poocoinImg {
  cursor: pointer;
  width: 30px;
}

.fourthHeaderDiv .dextoolImg {
  cursor: pointer;
  width: 20px;
}

.addwatchlistbtn {
  color: rgb(14, 122, 172);
  background-color: transparent;
  border: none;
  font-size: 17px;
  margin-right: 10px;
  margin-left: 150px;
}

.addwatchlistbtn:hover {
  font-size: 25px;
  cursor: pointer;
}

.headerRightDiv {
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  /* align-items: center; */
}

.mainDetailsDiv {
  display: flex;

  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.detailInfoWrapDiv {
  display: flex;
  flex-direction: column;
}

.flightImgDiv {
  width: 481px;
}
.flightImgDiv img {
  width: 100%;
}

.loadingDetailsDiv {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsWrappedDiv {
  width: 100%;
}
@media only screen and (max-width: 1600px) {
  .detailsHeaderDiv {
    flex-direction: column;
    align-items: center;
  }
  .headerLeftDiv {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .headerRightDiv {
    width: 500px;
  }

  .mainDetailsDiv {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  .firstHeaderDiv {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    height: 100%;
  }
  .logoRightDiv {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .headerRightDiv {
    display: none;
  }
  .firstHeaderDiv {
    flex-direction: column;
  }
  .firstHeaderDiv {
    margin-bottom: 10px;
  }
  .logoVoteDivWrapped {
    margin-top: 20px;
  }
  .mainDetailsDiv {
    flex-direction: column;
    align-items: center;
  }
  .addwatchlistbtn {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 1700px) {
  .secondHeaderDiv {
    margin-top: 10px;
  }
  .detailInfoWrapDiv {
    align-items: center;
    /* justify-content: center; */
  }
  .addwatchlistbtn {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .secondHeaderDiv {
    margin-top: 10px;
  }
  .headerLeftDiv {
    width: 300px;
  }
  .flightImgDiv {
    width: 300px;
  }
  .detailInfoWrapDiv {
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .addwatchlistbtn {
    margin-left: 10px;
  }
}
