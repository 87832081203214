.footer {
  width: 100%;
  /* height: 100%; */
  background-image: url('../../assets/img/footer/footerBgImg.png');
  background-size: cover;
  display: flex;
}

.logoBlackImg {
  display: flex;
  margin-left: 10px;
  width: 547px;
  margin-bottom: 10px;
  /* justify-content: center; */
}

.gridArea {
  /* padding-top: 20px;
    padding-left: 20px; */
  width: 500px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
}

.gridArea span {
  color: black;
  font-size: 20px;
  font-weight: 1000;
  padding-top: 5px;
  /* padding-bottom: 5px; */
}

@media only screen and (max-width: 1024px) {
  /* .logoBlackImg img{
       display:none;
    } */
  .logoBlackImg {
    /* width: 100%; */
    justify-content: center;
  }
  .footer {
    flex-direction: column;
    text-align: center;
  }
  .gridArea {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  /* .logoBlackImg img{
       display:none;
    } */
  .logoBlackImg {
    display: none;
  }
}
