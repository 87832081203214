.detailInfoDiv {
  width: 500px;
  /* height: 100%; */
  background-image: linear-gradient(to right, #dc1bd9, #4eb4c3);
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;

  border-top-right-radius: 15px;

  margin-top: 50px;
}

.detailInfoWrappedDiv {
  background-color: #290825;
  border-top-right-radius: 15px;
  padding: 30px 30px 30px 30px;
}

.detailInfoTitleDiv {
  font-size: 17px;
  font-weight: bold;
  color: white;
  border-bottom: #4eb4c3 1px solid;
  word-break: break-word;
}
.detailInfoContentDiv {
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  border-bottom: #4eb4c3 1px solid;
  word-break: break-word;
}

.detailInfoContentTierDiv {
  display: flex;
  align-items: center;
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  border-bottom: #4eb4c3 1px solid;
  word-break: break-word;
}

.tierInfoDivWrapped {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tierInfoDiv img {
  width: 20px;
  height: 20px;
}

.upgradeTierDiv {
  color: yellow;
}

.upgradeTierDiv img {
  color: yellow;
  width: 20px;
}
.upgradeTierDiv:hover {
  cursor: pointer;
}
.tierInfoDiv {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 1700px) {
  .detailInfoDiv {
    padding-left: 3px;
  }
}

@media only screen and (max-width: 600px) {
  .detailInfoDiv {
    width: 310px;
    padding-left: 3px;
  }
}
