.filterDivWrapped {
  width: calc(100% - 10px);
  /* height: 100%; */
  display: flex;
  justify-content: center;

  background-image: linear-gradient(to right, #dc1bd9, #4eb4c3);
  padding: 5px 5px 5px 5px;
  border-top-right-radius: 50px;
  position: relative;
  margin-top: 50px;
  margin-bottom: 150px;
}
.filterDiv {
  width: 100%;
  padding: 150px 20px 20px 20px;
  background-color: #290825;
  border-top-right-radius: 50px;
  z-index: 1;
}

.filterOptionDiv {
  position: absolute;
  width: calc(100% - 100px);
  height: 250px;
  top: -25px;
  left: 30px;
  background-color: transparent;
  /* overflow-x: scroll; */
  display: flex;
  align-items: top;
}

.diamondFilterDiv {
  width: 150px;
  min-width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
}
.diamondFilterWrappedDiv {
  width: 100%;
  height: 100%;
  background-color: #232c29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-radius: 20px;
}
.diamondFilterWrappedDiv:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
}

.rubyFilterDiv {
  margin-left: 20px;
  width: 150px;
  min-width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
}
.rubyFilterWrappedDiv {
  width: 100%;
  height: 100%;
  background-color: #232c29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-radius: 20px;
}
.rubyFilterWrappedDiv:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
}

.emeraldFilterDiv {
  margin-left: 20px;
  width: 150px;
  min-width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
  margin-right: 20px;
}
.emeraldFilterWrappedDiv {
  width: 100%;
  height: 100%;
  background-color: #232c29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-radius: 20px;
}
.emeraldFilterWrappedDiv:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
}

.networkFilterDiv {
  width: 150px;
  min-width: 150px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: 20px;
}

.networkTitleDiv {
  width: 150px;
  min-width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
}
.networkFilterTitleWrappedDiv {
  width: 100%;
  height: 100%;
  background-color: #232c29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-radius: 20px;
}
.networkFilterTitleWrappedDiv:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
}

.categoryFilterDiv {
  /* margin-left: auto; */
  z-index: 2;
  margin-right: 0;
}
.categoryTitleDiv {
  width: 150px;
  min-width: 150px;
  height: 50px;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
}
.categoryFilterWrappedDiv {
  width: 100%;
  height: 100%;
  background-color: #232c29;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  border-radius: 20px;
}
.categoryFilterWrappedDiv:hover {
  cursor: pointer;
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
}

.categorySubfilterDiv {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.voteBtnDiv {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 400px; */
}

.voteBtnWrapped {
  background-image: linear-gradient(to right, #f606f9, #3ebadf);
  padding: 3px 3px 3px 3px;
  border-radius: 20px;
  height: 40px;
  width: 130px;
}

.voteBtnWrapped {
  height: 40px;
  width: 130px;
  background-image: linear-gradient(to right, #ce3ca7, #4291ae);
  padding: 3px 3px 3px 3px;
  border-radius: 20px;
  margin-left: 10px;
}

.voteBtn {
  width: 100%;
  height: 100%;
  background-color: #290825;
  border-radius: 20px;
  font-size: 15px;
  color: white;
  border: none;
  /* border: 1px solid #F610FE; */
}

.voteBtn:hover {
  background-image: linear-gradient(to right, #dc1bd9, #4eb4c3);
}

.logoImgDiv {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.timerImgDIv {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}
.detailTitle {
  color: white;
}
.detailContent {
  color: #3fff00;
}

.logoImgDlg {
  width: 50px;
}
.modalAreaDiv {
  display: flex;
  justify-content: center;
}
.titleAreaDiv {
  text-align: center;
}
.wlImgDiv:hover {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .wlImgDiv img{
    width: 30px;
} */
.wlImgDiv button:hover {
  cursor: pointer;
}

.loadingDiv {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingDiv img {
  width: 200px;
  height: 200px;
  color: #3ebadf;
}

.subFilterDiv {
  display: flex;
  align-items: center;
}
/* Category Filter */
.categoryBatchDiv {
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.todayListedDiv {
  font-size: 17px;
  color: white;
  border-bottom: #3ebadf 1px solid;
  margin-right: 20px;
  background-color: #290825;
  text-align: center;
}
.todayListedDiv:hover {
  cursor: pointer;
  font-size: 20px;
}

.todayTopVoteDiv {
  font-size: 17px;
  color: white;
  border-bottom: #3ebadf 1px solid;
  margin-right: 20px;
  background-color: #290825;
}
.todayTopVoteDiv:hover {
  cursor: pointer;
  font-weight: bold;
}

.topVoteDiv {
  font-size: 17px;
  color: white;
  border-bottom: #3ebadf 1px solid;
  margin-right: 20px;
  background-color: #290825;
}
.topVoteDiv:hover {
  cursor: pointer;
  font-weight: bold;
}
.presaleDiv:hover {
  cursor: pointer;
  font-weight: bold;
}
.presaleDiv {
  font-size: 17px;
  color: white;
  border-bottom: #3ebadf 1px solid;
  margin-right: 20px;
  background-color: #290825;
}

/* Network Filter */
.networkBatchDiv {
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.ethDiv {
  font-size: 20px;
  color: white;
  border-bottom: #3ebadf 1px solid;
  margin-right: 20px;
}
.bscDiv {
  font-size: 20px;
  color: white;
  border-bottom: #3ebadf 1px solid;
}
.ethDiv:hover {
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
}
.bscDiv:hover {
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
}

.networkSubfilterDiv {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 17px;
}

.subFilterETH {
  background-color: #290825;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #3ebadf;
}
.subFilterBSC {
  background-color: #290825;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #3ebadf;
}
.subFilterETH:hover {
  font-size: 20px;
  cursor: pointer;
}
.subFilterBSC:hover {
  font-size: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 1600px) {
  .filterDiv {
    overflow-x: scroll;
    justify-content: start;
    padding-top: 80px;
    font-size: 15px;
    scrollbar-width: thin;
  }
  .filterOptionDiv {
    overflow-x: scroll;
  }
  .voteBtn {
    font-size: 12px;
  }

  .voteBtn {
    font-size: 12px;
  }

  .voteBtnWrapped {
    width: 100px;
  }

  .voteBtnWrapped {
    width: 100px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .loadingDiv {
    height: 180px;
  }

  .loadingDiv img {
    width: 100px;
    height: 100px;
    color: #3ebadf;
  }

  .diamondFilterDiv {
    height: 40px;
  }
  .diamondFilterWrappedDiv img {
    width: 25px;
  }
  .rubyFilterDiv {
    height: 40px;
  }
  .rubyFilterWrappedDiv img {
    width: 25px;
  }

  .emeraldFilterDiv {
    height: 40px;
  }
  .networkTitleDiv {
    height: 40px;
  }
  .categoryTitleDiv {
    height: 40px;
  }
  .emerald.FilterWrappedDiv img {
    width: 25px;
  }
  .categoryFilterDiv {
    height: 40px;
  }
  .networkFilterDiv {
    height: 40px;
  }

  .filterOptionDiv {
    height: 250px;
    top: -25px;
  }
}
