.mainPage {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/img/bg.png');
  background-size: cover;
  padding-bottom: 100px;
}

.navArea {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.navBtnWrappedDiv {
  padding: 1px 1px 1px 1px;
  background-image: linear-gradient(to right, #f610fe, #43b5e6);
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.navBtn {
  color: white;
  border: none;
  border-radius: 20px;

  height: 40px;
  width: 200px;
  font-size: 17px;
  background-color: #202d20;
}

.navBtn:hover {
  background-image: linear-gradient(to right, #f610fe, #43b5e6);
  cursor: pointer;
}

.btnArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.btnArea button {
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  width: 250px;
  min-width: 220px;
  background-color: transparent;
  color: white;
  border-image-source: linear-gradient(to right, #ac42a7, #7ea1af);
  border-width: 1pt;
  border-image-slice: 1;
  font-size: 17px;
  font-weight: 600;
}

.btnArea button:hover {
  background-image: linear-gradient(to right, #f610fe, #43b5e6);
}

.selectTools {
  padding-left: 30px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 40px;
  width: 250px;
  background-color: transparent;
  color: white;
  border-image-source: linear-gradient(to right, #ac42a7, #7ea1af);
  border-width: 1pt;
  border-image-slice: 1;
  font-size: 17px;
  font-weight: 600;
}

.selectTools:hover {
  background-image: linear-gradient(to right, #f610fe, #43b5e6);
}

.selectTools option {
  background: #040002;
}

.selectTools option:hover {
  background: #f610fe;
}

.logoImg img:hover {
  cursor: pointer;
}
.mainDiv {
  margin-top: 100px;
  /* margin-bottom: 20px; */
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1350px) {
  .btnArea {
    /* flex-direction: column; */
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 1920px) {
  .navBtns {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .mainDiv {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 700px) {
  .logoImg {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .inputDiv {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .navBtns {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mainDiv {
    margin-top: 50px;
  }
}
